//	eslint-disable-next-line

;(function () {
	const { viewerModel, thunderboltVersion } = window
	const { site, rollout, fleetConfig, requestUrl, experiments } = viewerModel
	const frogOnUserDomain = experiments['specs.thunderbolt.frog_on_user_domain']
	const fedOpsAppName = site.isResponsive ? 'thunderbolt-responsive' : 'thunderbolt'
	const { isDACRollout, siteAssetsVersionsRollout } = rollout
	const is_dac_rollout = isDACRollout ? 1 : 0
	const is_sav_rollout = siteAssetsVersionsRollout ? 1 : 0
	const is_rollout = fleetConfig.code === 0 || fleetConfig.code === 1 ? fleetConfig.code : null
	const ts = Date.now() - window.initialTimestamps.initialTimestamp
	const tsn = Math.round(performance.now()) // client only
	const { visibilityState } = document
	const pageVisibilty = visibilityState
	if (requestUrl.includes('suppressbi=true')) {
		return
	}
	const sendBeacon = (url: string): void => {
		const isIOS = (): boolean => /\(iP(hone|ad|od);/i.test(window?.navigator?.userAgent)
		let sent = false

		if (!isIOS()) {
			try {
				sent = navigator.sendBeacon(url)
			} catch (e) {
				//
			}
		}
		if (!sent) {
			new Image().src = url
		}
	}

	const frog = frogOnUserDomain ? site.externalBaseUrl.replace(/^https?:\/\//, '') + '/_frog' : '//frog.wix.com'
	const url =
		frog +
		'/bolt-performance?src=72&evid=' +
		'21' +
		'&appName=' +
		fedOpsAppName +
		'&is_rollout=' +
		is_rollout +
		'&is_sav_rollout=' +
		is_sav_rollout +
		'&is_dac_rollout=' +
		is_dac_rollout +
		'&dc=' +
		site.dc +
		'&msid=' +
		site.metaSiteId +
		'&session_id=' +
		window.fedops.sessionId +
		'&vsi=' +
		window.fedops.vsi +
		'&pv=' +
		pageVisibilty +
		'&pn=1' +
		'&v=' +
		thunderboltVersion +
		'&url=' +
		encodeURIComponent(requestUrl) +
		`&ts=${ts}&tsn=${tsn}`

	sendBeacon(url)
})()
